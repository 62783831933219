.header--inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header--w-menu {
    display: none;
}

@media (min-width: 1024px) {
    .header--w-menu {
        display: block;
    }
}

.header--menu {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 12px;
}

/* Accesories */

.allproducts {
    margin-top: 32px;
    margin-bottom: 32px;
}

.accessories--title {
    margin-bottom: 20px;
}

.allproducts--inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
}

@media (min-width: 1024px) {
    .allproducts--inner {
        flex-direction: row;
    }
}

.allproducts--accesory {
    flex-basis: 20%;
}

.product {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
}

/* Footer */

.footer {
    border-top: 1px solid #cccccc;
    padding-top: 12px;
    padding-bottom: 12px;
}

.footer--inner {
    display: flex;
    justify-content: center;
}
.hidden {
    display: none;
}
