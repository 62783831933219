.btn {
    display: inline-block;
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
    color: black;
    cursor: pointer;
    font-size: 14px;
}

.btn:hover {
    background-color: #999999;
    color: #ffffff;
}

/* Accesories */
.card {
    padding: 16px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    width: 210px;
}
.product-data {
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* General form styles */
form {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem;
}

label {
    width: 100%;
    display: block;
    margin-bottom: 10px;
}

input,
textarea,
select,
fieldset {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 1rem;
}

textarea {
    height: 10rem;
}

/* Product details styles */
.product-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}
