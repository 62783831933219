* {
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

body {
    font-family: "Roboto", sans-serif;
    color: #27272a;
    font-size: 18px;
}

/* Remove list styles (bullets/numbers) */
ul {
    list-style: none;
}
.wrapper {
    max-width: 1440px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.heading-3 {
    font-size: 1.375rem;
    line-height: 1.625rem;
    font-weight: 700;
}
.header {
    border-bottom: 1px solid #cccccc;
    padding-top: 16px;
    padding-bottom: 16px;
}
